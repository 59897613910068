body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.loading {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  z-index: 999999999999;
  background-image: url(./assets/images/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
}

#barcode-scanner.viewport {
  position: relative;
  width: 400px;
  height: 300px;
  overflow: hidden;
  margin: 0% auto;
}

#barcode-scanner.viewport > canvas,
#barcode-scanner.viewport > video {
  float: left;
  width: 320px;
  height: 300px;
}

canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.main-container {
  max-width: 370px; /* 643 is max mobile size for 6.7 inch phones */
  margin: 0 auto;
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: 370px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-header]:before {
  width: 80px !important;
}

@media screen and (max-width: 480px) {
  .main-container {
    max-width: inherit;
    margin: 0 auto;
  }
  :root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: #fff;
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: 100%;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
    --rsbs-overlay-rounded: 16px;
  }
}

.main-container-without-hf {
  margin: 0 auto;
}

.selectImage {
  height: 20px;
  width: 20px;
  margin-left: 2px;
  margin-right: 6px;
  border-radius: 12px;
  float: left;
  object-fit: fill;
}

.selectDropdown {
  border: none;
  appearance: none;
  -webkit-appearance: none;
  width: 85px;
  padding-left: 2px;
  padding-right: 2px;
  justify-content: center;
  margin-left: 2px;
  margin-right: 0px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.mafsignature {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  height: 48px;
  width: 149px;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}

.popup-close-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}

.popup-msg-body {
  color: #ffffff;
}

.Toastify {
  --toastify-toast-min-height: auto !important;
  --toastify-toast-width: 350px;
  --toastify-font-family: inherit !important  ;
}

.cu-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.form-item{
  position: relative;
}
.form-item input{
  display: block;
  height: 40px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all .3s ease;
  padding: 0 15px
}
.form-item label{
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 13px;
  left: 10px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  padding: 0 5px;
  color: #000;
  transition: all .3s ease
}
.form-item input:focus + label {
  font-size: 14px;
  top: -10px
}
.form-item input:valid + label {
  font-size: 14px;
  top: -10px
}

.form-item input:read-only + label {
  font-size: 14px;
  top: -10px
}

.custom-shadow {
  box-shadow: 0 0 10px rgb(0,0,0);
  -webkit-box-shadow: 0 0 10px rgb(0,0,0);
  -moz-box-shadow: 0 0 10px rgb(0,0,0);
}
